import React from 'react';

import { Icon } from '@mui/material';

import numberFormatter from '@/formatters/ShortNumberConverter';

const PercentageDifference = ({ difference }: { difference: number }) => {
  return (
    <div className={`percentage-difference-container ${difference < 0 ? 'red' : 'green'}`}>
      <p className={`status-text ${difference < 0 ? 'unsuccessful' : 'active'} pl8`}>
        {numberFormatter(difference, 1)}%
      </p>
      <Icon className={`status-text ${difference < 0 ? 'unsuccessful' : 'active'}`}>
        {difference < 0 ? 'arrow_drop_down' : 'arrow_drop_up'}
      </Icon>
    </div>
  );
};

export default PercentageDifference;
