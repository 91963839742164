import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import en from 'date-fns/locale/en-GB';

import { Country } from '@/constants/Countries';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import { WebsiteBuilderTourDateModel } from '@/models/WebsiteBuilder';
import { isValidUrl } from '@/utility/validations';

import { CountrySelector } from '../microcomponents/CountrySelector';
import CustomIcons from '../microcomponents/CustomIcons';

const AddTourDateModal = ({
  open,
  chosenTourDate,
  output,
}: {
  open: boolean;
  chosenTourDate: WebsiteBuilderTourDateModel | undefined;
  output: (value: WebsiteBuilderTourDateModel | boolean) => void;
}) => {
  const { t } = useTranslation();
  const { dispatchSnackbar } = useSnackbarContext();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const initialTourDateState: WebsiteBuilderTourDateModel = useMemo(() => {
    if (chosenTourDate) {
      return chosenTourDate;
    } else {
      return {
        date: parseISO(new Date().toISOString()),
        venue: '',
        city: '',
        country: '',
        ticketLink: '',
      };
    }
  }, [chosenTourDate]);

  const [tourDate, setTourDate] = useState<WebsiteBuilderTourDateModel>(initialTourDateState);

  const isValidTourDate = (tourDate: WebsiteBuilderTourDateModel) => {
    return tourDate.date && tourDate.venue && tourDate.city && tourDate.country;
  };

  useEffect(() => {
    setDialogOpen(open);
    return () => {
      setTourDate(initialTourDateState);
    };
  }, [initialTourDateState, open]);

  return (
    <>
      <Dialog
        className="p10"
        open={dialogOpen}
        onClose={(_, reason) => {
          if (reason === 'backdropClick') {
            output(false);
            setDialogOpen(false);
          }
          setDialogOpen(false);
        }}
      >
        <DialogTitle className="p0 d-flex jc-space-between">
          {chosenTourDate ? 'Edit your date' : t('WEBSITE-BUILDER.ADD-A-DATE')}
          <div>
            <Button
              className="icon-btn m0"
              onClick={() => {
                output(false);
              }}
            >
              <Icon>close</Icon>
            </Button>
          </div>
        </DialogTitle>
        <DialogContent className="p0 mt16">
          <p className="mb8">Date</p>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={en}>
            <DesktopDatePicker
              format="dd-MM-yyyy"
              value={parseISO(new Date(tourDate.date as string).toISOString())}
              onChange={(value) => {
                setTourDate({ ...tourDate, date: value });
              }}
            />
          </LocalizationProvider>
          <p className="mt16">
            Tickets <span className="text-faded">(Optional)</span>
          </p>
          <div className="pos-rel mt8">
            <CustomIcons className="input-prefix-icon" name="link" />
            <input
              className={`has-prefix-icon ${tourDate.ticketLink !== '' && !isValidUrl(tourDate.ticketLink || '') ? 'error-outline' : ''}`}
              placeholder={t('Paste a ticket link here')}
              value={tourDate.ticketLink ?? ''}
              onChange={(event) => setTourDate({ ...tourDate, ticketLink: event.target.value })}
            />
          </div>
          <p className="mt16">Venue</p>
          <div className="mt8">
            <input
              placeholder={t('Name of the venue')}
              value={tourDate.venue ?? ''}
              onChange={(event) => setTourDate({ ...tourDate, venue: event.target.value })}
            />
          </div>
          <p className="mt16">City</p>
          <div className="mt8">
            <input
              placeholder={t('What city is the venue in?')}
              value={tourDate.city ?? ''}
              autoComplete="none"
              onChange={(event) => setTourDate({ ...tourDate, city: event.target.value })}
            />
          </div>
          <p className="mt16">Country</p>
          <div className="mt8">
            <CountrySelector
              preselectedCountry={tourDate.country}
              outputCountry={(country: Country) => {
                setTourDate({ ...tourDate, country: country.abbr });
              }}
            />
          </div>
        </DialogContent>
        <DialogActions className="p0 mt32">
          <Button
            className="w100p m0 btn-white"
            onClick={() => {
              if (!isValidTourDate(tourDate)) {
                return dispatchSnackbar({
                  type: 'OPEN_SNACKBAR',
                  payload: {
                    message: t('WEBSITE-BUILDER.INVALID-TOUR-DATE'),
                    type: 'error',
                  },
                });
              } else {
                output(tourDate);
                setDialogOpen(false);
              }
            }}
          >
            {chosenTourDate ? t('COMMON.UPDATE') : t('WEBSITE-BUILDER.ADD-DATE')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddTourDateModal;
