import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { Button, Icon } from '@mui/material';

import usePagedAdverts from '@/hooks/adverts/usePagedAdverts';

import { navItemsExpanded } from '../../../constants/NavModel';
import CustomIcons from '../microcomponents/CustomIcons';

const MenuModal = ({ closeModalOutput }: { closeModalOutput: () => void }) => {
  const { t } = useTranslation();

  const { pagedAdverts } = usePagedAdverts();

  const [notifications, setNotifications] = useState<number>(0);

  useEffect(() => {
    if (pagedAdverts && pagedAdverts.length > 0) {
      setNotifications(pagedAdverts.length);
    }
  }, [pagedAdverts]);

  const closeModal = () => {
    closeModalOutput();
  };
  return (
    <div className="menu-modal-container">
      <Button
        className="icon-btn menu-close-btn"
        onClick={() => {
          closeModal();
        }}
      >
        <Icon>close</Icon>
      </Button>
      <div className="menu-items-container">
        {navItemsExpanded.map((item) => {
          return (
            <div key={item.title} className="min-h48" data-testid={`nav-bar-item-${item.name}`}>
              <NavLink
                className={'nav-link-item'}
                data-testid={`nav-item-${item.name}`}
                to={item.navLink}
                onClick={() => {
                  closeModal();
                }}
              >
                <CustomIcons name={item.icon} className="nav-link-icon material-symbols-outlined" />
                <span className={'nav-link-text'}>{t(item.title)}</span>
                {item.notifications && notifications > 0 && <div className="nav-notification">{notifications}</div>}
              </NavLink>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MenuModal;
