import React, { useCallback, useEffect, useState } from 'react';

import { Icon } from '@mui/material';

import { getAverageRgb } from '@/formatters/ImageColorAverage';

const InstaStoryTemplate = ({
  mediaUrl,
  mediaType,
  caption,
  ctaText,
}: {
  mediaUrl: string;
  mediaType: string;
  caption: string;
  ctaText: string;
}) => {
  const [rgbAverage, setRgbAverage] = useState<number[]>([0, 0, 0]);

  const getAverage = useCallback(async () => {
    const imageAverage2 = await getAverageRgb(mediaUrl);
    setTimeout(() => {
      setRgbAverage([imageAverage2[0], imageAverage2[1], imageAverage2[2]]);
    }, 10000);
  }, [mediaUrl]);

  useEffect(() => {
    getAverage();
  }, [getAverage]);

  return (
    <div
      className="instagram-story-card ml-auto mr-auto text-left"
      style={
        {
          '--card-background': `rgb(${rgbAverage[0]}, ${rgbAverage[1]}, ${rgbAverage[2]})`,
        } as React.CSSProperties
      }
    >
      <div className="insta-story-background header"></div>

      <div className="insta-header">
        <div className="d-flex gap8">
          <div className="social-profile-image"></div>
          <div className="mt-auto mb-auto">
            <div className="social-title"></div>
          </div>
        </div>
        <div className="ml-auto d-flex">
          <img className="mr8" src="/images/icons/insta-icons/insta-more-dots.svg" alt="" />
          <img src="/images/icons/facebook-icons/fb-close.svg" alt="" />
        </div>
      </div>
      <div
        className="insta-story-image"
        onClick={() => {
          getAverage();
        }}
      >
        {mediaType === 'image' && <img src={mediaUrl} alt="" />}
        {mediaType === 'video' && (
          <video
            loop
            muted
            src={mediaUrl}
            onMouseOver={(event: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
              (event.target as HTMLVideoElement).play();
              (event.target as HTMLVideoElement).controls = true;
            }}
            onMouseLeave={(event: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
              (event.target as HTMLVideoElement).pause();
              (event.target as HTMLVideoElement).currentTime = 0;
              (event.target as HTMLVideoElement).controls = false;
            }}
          />
        )}

        <p className="p32 instagram-font text-white">{caption}</p>
      </div>
      <div className="insta-story-background footer"></div>
      <div className="insta-story-footer">
        <div className="ml-auto mr-auto chevron">
          <Icon>expand_less</Icon>
        </div>
        <div className="ml-auto mr-auto cta-button text-black fw-bold instagram-font">{ctaText}</div>
      </div>
    </div>
  );
};

export default InstaStoryTemplate;
