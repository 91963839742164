import { SnackbarModel } from '@/contexts/SnackBarContext';

export type ActionMap<T> = {
  [Key in keyof T]: T[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: T[Key];
      };
};

export const handleUnknownError = (dispatchSnackbar: (value: SnackbarModel) => void) => {
  dispatchSnackbar({
    type: 'OPEN_SNACKBAR',
    payload: {
      message: 'An unknown error occurred, please contact support if this error persists.',
      type: 'error',
    },
  });
};

export const isDevelopment = import.meta.env.VITE_NODE_ENV === 'development';
