import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';

import { RegEx } from '@/constants/RegEx';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import useSpotifyArtistSearch from '@/hooks/spotify/useSpotifyArtistSearch';
import { useDebounce } from '@/hooks/useDebounce';
import { SpotifyArtistSearchModel } from '@/models/Spotify';
import SpotifyAPI from '@/network/SpotifyAPI';

const SpotifySearchArtist = ({
  spotifyArtist,
  isChangeArtist = false,
}: {
  spotifyArtist: (artist: SpotifyArtistSearchModel) => void;
  isChangeArtist?: boolean;
}) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [query, setQuery] = useState<string>('');
  const noImage = '/images/profile-placeholder.svg';
  const { dispatchSnackbar } = useSnackbarContext();
  const [searchOptions, setSearchOptions] = useState<SpotifyArtistSearchModel[]>([
    {
      name: t('SPOTIFY-SEARCH.START-TYPING'),
      images: [{ url: noImage }],
      id: '',
    },
  ]);
  const { spotifyArtistSearchResult, spotifyArtistSearchResultError } = useSpotifyArtistSearch({ search: query });

  const [chosenArtist, setChosenArtist] = useState<SpotifyArtistSearchModel>({ id: '', name: '' });

  useEffect(() => {
    if (!spotifyArtistSearchResultError) return;

    dispatchSnackbar({
      type: 'OPEN_SNACKBAR',
      payload: {
        message: 'Error searching Spotify',
        type: 'error',
      },
    });
  }, [dispatchSnackbar, spotifyArtistSearchResultError]);

  const getSpotifyArtistByUrl = async (url: string) => {
    const result = await SpotifyAPI.getSpotifyArtist({ spotifyArtistUrl: url });
    if (!result) return;
    setSearchOptions([result.data]);
    setSearchQuery(url);
  };

  const isValidSpotifyUrl = (url: string) => {
    return RegEx.spotifyRegEx.test(url);
  };

  useEffect(() => {
    if (!spotifyArtistSearchResult || spotifyArtistSearchResult.length === 0) return;
    setSearchOptions(spotifyArtistSearchResult);
  }, [spotifyArtistSearchResult]);

  useDebounce(
    () => {
      if (!searchQuery || isValidSpotifyUrl(searchQuery)) return;

      return setQuery(searchQuery);
    },
    300,
    [searchQuery, setQuery]
  );

  const handleChosenArtist = (value?: string | SpotifyArtistSearchModel | null) => {
    if (!value) return;
    if (typeof value === 'string') return;
    setChosenArtist(value);
    spotifyArtist(value);
  };

  return (
    <>
      <label>
        <p>{isChangeArtist ? t('SPOTIFY-SEARCH.CHANGE-SPOTIFY-ARTIST') : t('SPOTIFY-SEARCH.FIND-SPOTIFY-ARTIST')}</p>
        <Autocomplete
          options={searchOptions}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
          isOptionEqualToValue={() => true}
          filterOptions={(options, state) => {
            if (isValidSpotifyUrl(state.inputValue)) {
              return options;
            }
            return options.filter((option) => option.name.toLowerCase().includes(state.inputValue.toLowerCase()));
          }}
          noOptionsText={'Sorry, we could not find any artists'}
          value={chosenArtist}
          inputValue={searchQuery}
          onChange={(_, value: string | SpotifyArtistSearchModel | null | undefined) => handleChosenArtist(value)}
          onInputChange={(_, value) => {
            if (isValidSpotifyUrl(value)) {
              return getSpotifyArtistByUrl(value);
            } else {
              setSearchQuery(value);
            }
          }}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.images && option.images.length > 0 ? (
                <img className="search-list-image" src={option.images[0]?.url} alt="" />
              ) : (
                <img className="search-list-image" src={noImage} alt="" />
              )}
              <span className="pl16 text-white">{option.name}</span>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              // onChange={async (event: ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value)}
              placeholder={t('SPOTIFY-SEARCH.SEARCH-FOR-AN-ARTIST')}
              sx={{
                '& .MuiInputLabel-outlined': {
                  paddingLeft: '20px',
                  borderColor: 'white',
                },
                '& .MuiInputLabel-shrink': {
                  marginLeft: '20px',
                  paddingLeft: '10px',
                  paddingRight: 0,
                  borderColor: 'white',
                },
                '& .MuiAutocomplete-listbox': {
                  maxHeight: 200,
                  overflow: 'auto',
                },
              }}
              InputLabelProps={params}
            />
          )}
        />
      </label>
    </>
  );
};

export default SpotifySearchArtist;
