import React from 'react';

import numberFormatter from '@/formatters/ShortNumberConverter';
import { TikTokAdsVideoModel } from '@/models/TikTok';

const TikTokPostTemplate = ({ postDetails, isOverlay }: { postDetails: TikTokAdsVideoModel; isOverlay?: boolean }) => {
  return (
    <div className="tiktok-post ml-auto mr-auto">
      {/* autoPlay */}
      <video src={postDetails?.video_info?.url} autoPlay muted loop></video>
      {!isOverlay && (
        <div className="tiktok-duration">
          <p className="small">{numberFormatter(postDetails.video_info.duration)}s</p>
        </div>
      )}
      {isOverlay && (
        <div>
          <div className="pos-abs d-flex flex-d-col gap30 jc-center tiktok-icons max-w400">
            <div className="social-profile-image"></div>
            <img className="m-auto" src="/images/icons/tiktok-icons/tiktok-heart.svg" alt="" />
            <img className="m-auto" src="/images/icons/tiktok-icons/tiktok-comment.svg" alt="" />
            <img className="m-auto" src="/images/icons/tiktok-icons/tiktok-save.svg" alt="" />
            <img className="m-auto" src="/images/icons/tiktok-icons/tiktok-share.svg" alt="" />
            <div className="social-profile-image"></div>
          </div>
          <div className="tiktok-text-and-button">
            <div className="tiktok-title"></div>
            <div className="tiktok-subtitle mt8"></div>
            <div className="tiktok-subtitle short mt8"></div>
            <div className="tiktok-listen-now p8 mt10">Listen Now</div>
          </div>
        </div>
      )}
      {!isOverlay && <p className="small text-left mb8">{postDetails.text}</p>}
    </div>
  );
};

export default TikTokPostTemplate;
