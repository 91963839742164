import React, { useState } from 'react';

import { Button } from '@mui/material';

import useAccountContext from '@/hooks/context/useAccountContext';

const FacebookLoginButton = ({ isWide }: { isWide?: boolean }) => {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const { refetchAccount } = useAccountContext();

  const facebookAppId: string = import.meta.env.VITE_FACEBOOK_APP_ID || '';
  const redirect_uri = `${window.location.origin}/meta-callback`;
  const scopes = 'ads_read,ads_management,pages_show_list,pages_read_engagement,business_management';
  const state = '';

  const route = `https://m.facebook.com/v18.0/dialog/oauth?app_id=${facebookAppId}&redirect_uri=${redirect_uri}&scope=${scopes}&state=${state}&response_type=token`;

  const loginToMeta = () => {
    setShowMessage(true);
    window.open(`${route}`, '', 'popup=true,width=500px,height=800px');
    window.addEventListener(
      'message',
      (event) => {
        if (event.data === 'Meta logged in') {
          refetchAccount();
          setShowMessage(false);
        }
        if (event.data === 'Meta log in denied') {
          refetchAccount();
          setShowMessage(false);
        }
      },
      true
    );
  };

  return (
    <>
      <Button className={`m0 ${isWide ? 'w100p mt10' : ''}`} onClick={loginToMeta}>
        Login to Meta
      </Button>
      {showMessage && <p className="mt16">We're on it! If there's an error, click to retry.</p>}
    </>
  );
};

export default FacebookLoginButton;
