import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, Button, Icon, Modal } from '@mui/material';

import ArtistAPI from '@/network/ArtistAPI';
import { handleApiError } from '@/utility/api';

import AutoCompleteInput from '../microcomponents/AutoCompleteInput';

const AddGenreModal = ({
  title,
  isOpen,
  onClose,
  outputGenre,
}: {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  outputGenre: (genre: string) => void;
}) => {
  const { t } = useTranslation();

  const [selectedGenre, setSelectedGenre] = useState<string>('');
  const [genreOptions, setGenreOptions] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const searchGenres = useCallback(async (search: string) => {
    try {
      const response = await ArtistAPI.searchGenre({ searchValue: search });
      setGenreOptions(response.data.genres);
    } catch (error: unknown) {
      handleApiError({ error });
    }
  }, []);

  useEffect(() => {
    if (searchValue.length) {
      searchGenres(searchValue);
    }
  }, [searchGenres, searchValue]);

  const handleSaveGenre = useCallback(() => {
    outputGenre(selectedGenre);
  }, [outputGenre, selectedGenre]);

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <>
          <div className="to-do-modal">
            <div className="d-flex jc-space-between mb16">
              <div>
                <h4>{t(title)}</h4>
              </div>
              <div>
                <Button className="icon-btn m0 mt-10 mr-10" onClick={onClose}>
                  <Icon>close</Icon>
                </Button>
              </div>
            </div>
            <div className="pos-rel">
              <Autocomplete
                options={genreOptions}
                noOptionsText={<p className="text-white">No options available</p>}
                getOptionLabel={(option) => option}
                value={selectedGenre}
                isOptionEqualToValue={() => true}
                onChange={(_, value) => {
                  if (!value) return;
                  setSelectedGenre(value);
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    <div className="d-flex">
                      <div>
                        <p>{option}</p>
                      </div>
                    </div>
                  </li>
                )}
                renderInput={(params) => (
                  <AutoCompleteInput
                    params={params}
                    placeholder={t('PROFILE.SEARCH-FOR-GENRES')}
                    onChange={(event) => setSearchValue(event.target.value)}
                  />
                )}
              />
            </div>
            <Button className="btn-black-2 w100p ml0 mt20" onClick={handleSaveGenre}>
              {t('COMMON.DONE')}
            </Button>
          </div>
        </>
      </Modal>
    </>
  );
};

export default AddGenreModal;
