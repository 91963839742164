type IntercomFunction = {
  (...args: unknown[]): void;
  q: unknown[];
  c(args: unknown[]): void;
};

export const loadIntercomScript = () => {
  const APP_ID = import.meta.env.VITE_INTERCOM_APP_ID;
  const w = window;
  const ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    const d = document;
    const i: IntercomFunction = function (...args: unknown[]) {
      i.c(args);
    };
    i.q = [];
    i.c = function (args: unknown[]) {
      i.q.push(args);
    };
    w.Intercom = i;
    const l = function () {
      const script = d.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://widget.intercom.io/widget/' + APP_ID;
      const x = d.getElementsByTagName('script')[0];
      x.parentNode!.insertBefore(script, x);
    };
    if (document.readyState === 'complete') {
      l();
    } else {
      w.addEventListener('load', l, false);
    }
  }
};
