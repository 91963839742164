import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Loading = ({ size, noText }: { size?: string; noText?: boolean }) => {
  const { t } = useTranslation();
  const [loadingMessage, setLoadingMessage] = useState<string>('');

  const startInterval = () => {
    setTimeout(() => {
      setLoadingMessage('LOADING-MESSAGES.LOADING-MESSAGE-1');
    }, 5000);
    setTimeout(() => {
      setLoadingMessage('LOADING-MESSAGES.LOADING-MESSAGE-2');
    }, 15000);
    setTimeout(() => {
      setLoadingMessage('LOADING-MESSAGES.LOADING-MESSAGE-3');
    }, 25000);
  };

  useEffect(() => {
    if (noText) {
      return;
    }
    startInterval();
  }, [noText]);

  if (size === 'extra-small') {
    return (
      <div className="pos-rel text-center">
        <div className="loading-container loading-extra-small" data-testid="loading-component-small">
          <div className="circle circle-extra-small circle-1"></div>
          <div className="circle circle-extra-small circle-2"></div>
        </div>
        <p className="small">{t(loadingMessage)}</p>
      </div>
    );
  }

  if (size === 'small') {
    return (
      <div className="pos-rel text-center">
        <div className="loading-container loading-small" data-testid="loading-component-small">
          <div className="circle circle-small circle-1"></div>
          <div className="circle circle-small circle-2"></div>
        </div>
        <p className="small">{t(loadingMessage)}</p>
      </div>
    );
  }
  return (
    <div className="pos-rel text-center">
      <div className="loading-container" data-testid="loading-component">
        <div className="circle circle-1"></div>
        <div className="circle circle-2"></div>
      </div>
      <p className="small">{t(loadingMessage)}</p>
    </div>
  );
};

export default Loading;
