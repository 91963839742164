import React from 'react';

export const DesktopScreen = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="pos-rel ml-auto mr-auto w100p max-w630">
      <img src="/images/images/laptop.png" alt="desktop" width={'100%'} />
      <div className="pos-abs desktop-screen"> {children}</div>
    </div>
  );
};
