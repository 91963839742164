import React from 'react';

const SaveWithProBadge = () => {
  return (
    <div className={`percentage-difference-container save-with-pro`}>
      <p className={`status-text active small pt0 fw-bold pl4 pr4`}>{'SAVE WITH PRO'}</p>
    </div>
  );
};

export default SaveWithProBadge;
