import React from 'react';

export const TabletScreen = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="pos-rel ml-auto mr-auto w100p max-w380">
      <img src="/images/images/tablet.webp" alt="tablet" width={'100%'} />
      <div className="pos-abs tablet-screen"> {children}</div>
    </div>
  );
};
