import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Icon } from '@mui/material';

const DialogModal = ({
  open,
  output,
  title,
  content,
  type,
  isInfoDialog,
  isLink,
  isWebsiteConfirmation,
  isDelete,
}: {
  open: boolean;
  type?: string;
  output: (value: boolean) => void;
  title: string;
  content?: string;
  isInfoDialog?: boolean;
  isLink?: { link: string; title: string };
  isWebsiteConfirmation?: boolean;
  isDelete?: boolean;
}) => {
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    setDialogOpen(open);
  }, [open]);

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={(_, reason) => {
          if (reason === 'backdropClick') {
            output(false);
            setDialogOpen(false);
          }
          setDialogOpen(false);
        }}
      >
        <DialogTitle>{t(title)}</DialogTitle>
        <DialogContent className="pb0">
          <p className="text-faded">{t(content || '')}</p>
          {type === 'permissions' && (
            <div
              className="d-flex mt8"
              onClick={() => {
                setIsChecked(!isChecked);
              }}
            >
              <Checkbox
                className="text-white pl0"
                checked={isChecked}
                checkedIcon={<Icon className="material-symbols-outlined pl0">radio_button_checked</Icon>}
                icon={<Icon className="material-symbols-outlined pl0 text-faded">radio_button_unchecked</Icon>}
              ></Checkbox>
              <p className="mt10 cursor-pointer">I confirm I have the legal right to represent this artist</p>
            </div>
          )}
          {isLink && (
            <Button
              className="w100p btn-white ml0 mr0 mt20"
              onClick={() => {
                window.open(isLink.link);
              }}
            >
              {t(isLink.title)}
            </Button>
          )}
        </DialogContent>
        <DialogActions>
          {isInfoDialog && (
            <Button
              className="w100p"
              onClick={() => {
                output(false);
                setDialogOpen(false);
              }}
            >
              {t('COMMON.DONE')}
            </Button>
          )}
          {!isInfoDialog && !isWebsiteConfirmation && !isDelete && (
            <Button
              className="btn-white min-w120"
              onClick={() => {
                output(false);
                setDialogOpen(false);
              }}
            >
              {t('COMMON.NO-CONTINUE')}
            </Button>
          )}
          {!isInfoDialog && !isWebsiteConfirmation && !isDelete && (
            <Button
              className="min-w120"
              disabled={type === 'permissions' && !isChecked}
              onClick={() => {
                output(true);
                setDialogOpen(false);
              }}
            >
              {t('COMMON.YES-QUIT')}
            </Button>
          )}
          {isWebsiteConfirmation && (
            <Button
              className="min-w120"
              onClick={() => {
                output(false);
                setDialogOpen(false);
              }}
            >
              {t('COMMON.GO-BACK')}
            </Button>
          )}
          {isWebsiteConfirmation && (
            <Button
              className="btn-white min-w120"
              disabled={type === 'permissions' && !isChecked}
              onClick={() => {
                output(true);
                setDialogOpen(false);
              }}
            >
              {t('COMMON.CONFIRM')}
            </Button>
          )}
          {isDelete && (
            <Button
              className="min-w120"
              onClick={() => {
                output(false);
                setDialogOpen(false);
              }}
            >
              {t('COMMON.GO-BACK')}
            </Button>
          )}
          {isDelete && (
            <Button
              className="btn-white min-w120"
              onClick={() => {
                output(true);
                setDialogOpen(false);
              }}
            >
              <span className="text-error">{t('COMMON.YES-DELETE')}</span>
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogModal;
