import React, { SyntheticEvent, useCallback } from 'react';

import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

import useSnackbarContext from '@/hooks/context/useSnackbarContext';

const SnackbarComponent = () => {
  const { open, snackbar, dispatchSnackbar } = useSnackbarContext();

  const handleClose = (_: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatchSnackbar({ type: 'CLOSE_SNACKBAR' });
  };

  const renderSnackbarIcon = useCallback(() => {
    if (!snackbar) return <></>;
    switch (snackbar.type) {
      case 'success':
        return <Icon>task_alt</Icon>;
      case 'error':
        return <Icon className="material-symbols-outlined">error</Icon>;
      default:
        <Icon className="material-symbols-outlined">info</Icon>;
    }
  }, [snackbar]);

  if (!snackbar) return <></>;

  return (
    <>
      <Snackbar
        className={`snackbar-color-${snackbar?.type}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={
          <div className="d-flex">
            {renderSnackbarIcon()}
            <span className="pl16 pt2"> {snackbar.message}</span>
          </div>
        }
        action={
          <IconButton className="icon-btn no-bg" size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <Icon fontSize="small">close</Icon>
          </IconButton>
        }
      />
    </>
  );
};

export default SnackbarComponent;
