import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Modal } from '@mui/material';

import { isValidUrl } from '@/utility/validations';

import CustomIcons from '../microcomponents/CustomIcons';

const AddLinkModal = ({
  title,
  isOpen,
  onClose,
  outputLink,
  value,
  isProfile,
}: {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  outputLink: (link: string) => void;
  value: string;
  isProfile?: boolean;
}) => {
  const { t } = useTranslation();
  const [link, setLink] = useState<string>('');

  useEffect(() => {
    if (!value) return;

    setLink(value);
  }, [value]);

  const sendLink = () => {
    outputLink(link);
    onClose();
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <div className="to-do-modal">
          <div className="d-flex jc-space-between mb16">
            <div>
              <h4>{t(title)}</h4>
              <p className="text-faded small mt8">
                {t('WEBSITE-BUILDER.PASTE-A-LINK-TO-YOUR')}
                {t(title)} {isProfile ? t('COMMON.PROFILE-LOWER') : ''}
              </p>
            </div>
            <div>
              <Button className="icon-btn m0 mt-10 mr-10" onClick={onClose}>
                <Icon>close</Icon>
              </Button>
            </div>
          </div>
          <div className="pos-rel">
            <CustomIcons className="input-prefix-icon" name="link" />
            <input
              className={`has-prefix-icon ${link !== '' && !isValidUrl(link) ? 'error-outline' : ''}`}
              placeholder={
                t(title) + ' ' + (isProfile ? t('COMMON.PROFILE-LOWER') + ' ' : '') + t('WEBSITE-BUILDER.LINK')
              }
              value={link ?? ''}
              onChange={(event) => setLink(event.target.value)}
            />
          </div>
          <Button className="btn-black-2 w100p ml0 mt20" onClick={sendLink}>
            {t('COMMON.DONE')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AddLinkModal;
