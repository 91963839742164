import { addMinutes, format as formatDate, formatDistanceToNow, setMinutes, setSeconds } from 'date-fns';
import moment from 'moment';

export const formatDateTimeAgo = (date: Date) => {
  const today = new Date();
  const dayDiff = today.getDate() - date.getDate();

  if (dayDiff === 0) return 'Today';
  if (dayDiff === 1) return 'Yesterday';
  if (dayDiff === -1) return 'Tomorrow';
  if (dayDiff > 1 && dayDiff < 7) return `Last ${formatDate(date, 'EEEE')}`;
  if (dayDiff < -1 && dayDiff > -7) return formatDate(date, 'EEEE');
  return `${formatDistanceToNow(date)} ago`;
};

export const getNextQuarterHour = (date: Date): Date => {
  const minutes = date.getMinutes();
  const nextQuarter = 15 * Math.ceil(minutes / 15);
  if (nextQuarter >= 60) {
    return setSeconds(setMinutes(addMinutes(date, 15), 0), 0);
  }
  return setSeconds(setMinutes(date, nextQuarter), 0);
};

export const dateTimeFormatter = (value: string, format?: string) => {
  const date = moment(value).format('DD/MM/YY');
  const time = moment(value).format('LT');
  if (format === 'date') {
    return date;
  }
  if (format === 'APIdate') {
    return moment(value).format('YYYY-MM-DD');
  }
  if (format === 'year') {
    return moment(value).format('YYYY');
  }

  if (format === 'dateTimeAgo') {
    return moment(value).calendar(null, {
      lastWeek: '[Last] dddd',
      lastDay: '[Yesterday]',
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      sameElse: function () {
        return '[' + moment(value).fromNow() + ']';
      },
    });
  }

  return `${date} at ${time}`;
};
