import React, { useCallback, useEffect, useState } from 'react';

import { Icon } from '@mui/material';
import { format } from 'date-fns';

const PlaylistPitchStatus = ({ status, placedOn }: { status: number; placedOn?: string }) => {
  const [icon, setIcon] = useState<string>();
  const [word, setWord] = useState<string>();
  const [textColor, setTextColor] = useState<string>('');

  const getIconName = useCallback(() => {
    switch (status) {
      case 0: {
        setIcon('schedule');
        setWord('Pending');
        setTextColor('pending');
        break;
      }
      case 1: {
        setIcon('check_circle');
        if (placedOn) {
          setWord(`Added on ${format(new Date(placedOn), 'dd/MM/yyyy')}`);
        } else {
          setWord('Successful');
        }
        setTextColor('active');
        break;
      }
      case 2: {
        setIcon('cancel');
        setWord('Unsuccessful');
        setTextColor('unsuccessful');

        break;
      }
    }
  }, [placedOn, status]);

  useEffect(() => {
    getIconName();
  }, [getIconName]);

  return (
    <>
      <div className={`d-flex jc-start status-text ${textColor}`}>
        <Icon className={`fs-sm pt2 status-text ${textColor} material-symbols-outlined`}>{icon}</Icon>
        <p className={`capitalize small pl4 pt0 status-text ${textColor}`}>{word}</p>
      </div>
    </>
  );
};

export default PlaylistPitchStatus;
