/* eslint-disable no-console */
import React, { Component, ReactNode } from 'react';

import { appInsights } from '@/services/ApplicationInsightsService';
import { isDevelopment } from '@/utility/generic';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { hasError: false };
  }
  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    this.handleError(error);
  }

  async handleError(error: Error) {
    if (isDevelopment) {
      console.log(error);
    } else {
      appInsights.trackException({ exception: error });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div id="ErrorBoundary" style={{ alignItems: 'center' }} className="login-page flex-d-col">
          <div className="container text-center">
            <div className="login-header mb50">
              <img src="/images/logos/full-text-logo.svg" alt="logo"></img>
            </div>
            <h3>Something went wrong</h3>
            <button type="button" style={{ alignItems: 'center' }} onClick={() => this.setState({ hasError: false })}>
              Try again?
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
