import React from 'react';

import { Icon } from '@mui/material';

const InstaPostTemplate = ({
  pageName,
  mediaUrl,
  mediaType,
  caption,
  ctaText,
}: {
  pageName?: string;
  mediaUrl: string;
  mediaType: string;
  caption: string;
  ctaText: string;
}) => {
  return (
    <div className="instagram-card ml-auto mr-auto text-left">
      <div className="insta-header">
        <div className="d-flex gap8">
          <div className="social-profile-image"></div>
          <div className="mt-auto mb-auto">
            <div className="social-title"></div>
          </div>
        </div>
        <div className="ml-auto d-flex">
          <img src="/images/icons/insta-icons/insta-more-dots.svg" alt="" />
        </div>
      </div>
      <div className="insta-image">
        {mediaType === 'image' && <img src={mediaUrl} alt="" />}
        {mediaType === 'video' && (
          <video
            loop
            muted
            src={mediaUrl}
            onMouseOver={(event: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
              (event.target as HTMLVideoElement).play();
              (event.target as HTMLVideoElement).controls = true;
            }}
            onMouseLeave={(event: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
              (event.target as HTMLVideoElement).pause();
              (event.target as HTMLVideoElement).currentTime = 0;
              (event.target as HTMLVideoElement).controls = false;
            }}
          />
        )}
      </div>
      <div className="insta-cta text-black">
        <p className="text-black instagram-font">{ctaText}</p>
        <Icon>chevron_right</Icon>
      </div>
      <div className="insta-icons">
        <img src="/images/icons/insta-icons/insta-like.svg" alt="" />
        <img className="pl8" src="/images/icons/insta-icons/insta-comment.svg" alt="" />
        <img className="pl8" src="/images/icons/insta-icons/insta-send.svg" alt="" />
        <img className="ml-auto" src="/images/icons/insta-icons/insta-save.svg" alt="" />
      </div>
      <div className="insta-content pl12 pr12 pb12 pt0">
        <p className="text-black small instagram-font">
          <span className="fw-bold">{pageName || 'un:hurd'}</span>
          <span className="pl8">{caption}</span>
        </p>
      </div>
    </div>
  );
};

export default InstaPostTemplate;
