import React from 'react';

const FacebookPostTemplate = ({
  mediaUrl,
  mediaType,
  headline,
  description,
  text,
  ctaText,
  link,
}: {
  mediaUrl: string;
  mediaType: string;
  headline: string;
  description: string;
  text: string;
  ctaText: string;
  link: string;
}) => {
  return (
    <div className="facebook-card">
      <div className="fb-header">
        <div className="d-flex gap8">
          <div className="social-profile-image"></div>
          <div className="mt-auto mb-auto">
            <div className="social-title"></div>
            <div className="social-subtitle"></div>
          </div>
        </div>
        <div className="ml-auto d-flex">
          <img className="mr8" src="/images/icons/facebook-icons/fb-menu-dots.svg" alt="" />
          <img src="/images/icons/facebook-icons/fb-close.svg" alt="" />
        </div>
      </div>
      <p className="text-black pl12 pr12 pb12 pt0 small">{text}</p>
      <div className="fb-image">
        {mediaType === 'image' && <img src={mediaUrl} alt="" />}
        {mediaType === 'video' && (
          <video
            loop
            muted
            src={mediaUrl}
            onMouseOver={(event: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
              (event.target as HTMLVideoElement).play();
              (event.target as HTMLVideoElement).controls = true;
            }}
            onMouseLeave={(event: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
              (event.target as HTMLVideoElement).pause();
              (event.target as HTMLVideoElement).currentTime = 0;
              (event.target as HTMLVideoElement).controls = false;
            }}
          />
        )}
      </div>
      <div className="fb-content">
        <div className="w70p">
          <p className="text-black small pt0">{link}</p>
          <p className="text-black fw-bold small pt0">{headline}</p>
          <p className="text-black small pt0">{description}</p>
        </div>
        <div className="fb-cta text-black min-w82">{ctaText}</div>
      </div>
      <div className="fb-footer">
        <img src="/images/icons/facebook-icons/fb-like.svg" alt="" />
        <img src="/images/icons/facebook-icons/fb-comment.svg" alt="" />
        <img src="/images/icons/facebook-icons/fb-share.svg" alt="" />
      </div>
    </div>
  );
};

export default FacebookPostTemplate;
