import React, { useEffect, useMemo, useState } from 'react';

import { MenuItem, Select, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';

import { DateTimes } from '@/constants/DateTimes';
import useSubscription from '@/hooks/account/useSubscription';
import { DateRanges } from '@/models/Generic';

const DateTimeSelector = ({
  dateOutput,
  defaultRange,
  toggleButtons,
}: {
  dateOutput: (date: string) => void;
  toggleButtons?: boolean;
  defaultRange?: DateRanges;
}) => {
  const { isSubscribed } = useSubscription();

  const dateTimes: { name: DateRanges; toggleName: string; value: string; isDisabled: boolean }[] = useMemo(
    () => [
      {
        name: 'This week',
        toggleName: '1W',
        value: DateTimes.oneWeekAgoDate,
        isDisabled: false,
      },
      {
        name: 'This month',
        toggleName: '1M',
        value: DateTimes.oneMonthAgoDate,
        isDisabled: !isSubscribed,
      },
      {
        name: '3 months',
        toggleName: '3M',
        value: DateTimes.threeMonthAgoDate,
        isDisabled: !isSubscribed,
      },
      {
        name: 'This year',
        toggleName: '1Y',
        value: DateTimes.oneYearAgoDate,
        isDisabled: !isSubscribed,
      },
      {
        name: 'All time',
        toggleName: 'All',
        value: DateTimes.allTime,
        isDisabled: !isSubscribed,
      },
    ],
    [isSubscribed]
  );

  const [chosenDateTime, setChosenDateTime] = useState<string>(
    (isSubscribed && dateTimes.find((date) => date.name === defaultRange)?.value) || dateTimes[0].value
  );

  useEffect(() => {
    if (chosenDateTime) {
      dateOutput(chosenDateTime);
    }
  }, [chosenDateTime, dateOutput]);

  useEffect(() => {
    if (!isSubscribed) {
      setChosenDateTime(dateTimes[0].value);
    }
  }, [dateTimes, isSubscribed]);

  if (!toggleButtons) {
    return (
      <Select
        className="date-time-selector"
        value={chosenDateTime}
        defaultValue=""
        onChange={(event) => setChosenDateTime(event.target.value)}
      >
        {dateTimes.map((item) => (
          <MenuItem key={item.name} value={item.value} disabled={item.isDisabled}>
            {item.name}
            {item.isDisabled && <img className="pl8" src="/images/logos/subscription-pro-lock-logo.svg" alt="lock" />}
          </MenuItem>
        ))}
      </Select>
    );
  }

  return (
    <ToggleButtonGroup
      className="date-time-toggle-selector"
      value={chosenDateTime}
      exclusive
      onChange={(_, value) => setChosenDateTime(value)}
    >
      {dateTimes.map((item, index: number) => (
        <ToggleButton key={index} value={item.value} disabled={item.isDisabled}>
          <Tooltip key={index} title={!item.isDisabled ? item.name : 'Disabled'}>
            <p>{item.toggleName}</p>
          </Tooltip>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default DateTimeSelector;
