import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon } from '@mui/material';

const ErrorDialog = ({
  errorTitle,
  errorContent,
  open,
  onClose,
  onRetry,
}: {
  errorTitle: string;
  errorContent: string;
  open: boolean;
  onClose: () => void;
  onRetry: () => void;
}) => {
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    setDialogOpen(open);
  }, [open]);

  return (
    <Dialog
      className="p10"
      open={dialogOpen}
      onClose={() => {
        onClose();
        setDialogOpen(false);
      }}
    >
      <DialogTitle className="d-flex mt-auto mb-auto gap16 pl4 pr4">
        <h4>{t(errorTitle)}</h4>
        <Button className="icon-btn m0 mr0 ml-auto" onClick={onClose}>
          <Icon>close</Icon>
        </Button>
      </DialogTitle>
      <DialogContent className="pl4 pr4">{t(errorContent)}</DialogContent>
      {onRetry && (
        <DialogActions className="pl4 pr4">
          <Button className="btn-white w100p m0" onClick={onRetry}>
            {t('COMMON.TRY-AGAIN')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ErrorDialog;
