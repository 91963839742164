import React, { ChangeEvent } from 'react';

import { AutocompleteRenderInputParams, Icon, TextField } from '@mui/material';

const AutoCompleteInput = ({
  params,
  placeholder,
  onChange,
}: {
  params: AutocompleteRenderInputParams;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className="pos-rel">
      <Icon className="autocomplete-prefix-icon text-faded">search</Icon>
      <TextField
        {...params}
        placeholder={placeholder}
        onChange={onChange}
        sx={{
          '& .MuiInputLabel-outlined': {
            paddingLeft: '20px',
            borderColor: 'white',
          },
          '& .MuiInputLabel-shrink': {
            marginLeft: '20px',
            paddingLeft: '10px',
            paddingRight: 0,
            borderColor: 'white',
          },
          '& .MuiAutocomplete-listbox': {
            maxHeight: 200,
            overflow: 'auto',
          },
        }}
        InputProps={params.InputProps}
        InputLabelProps={params}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password', // disables autocomplete and autofill
        }}
      />
    </div>
  );
};

export default AutoCompleteInput;
