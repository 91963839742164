import React, { useCallback, useEffect, useState } from 'react';

import { Icon } from '@mui/material';

const CampaignStatus = ({ status }: { status?: string }) => {
  const [icon, setIcon] = useState<string>();

  const getIconName = useCallback(() => {
    switch (status) {
      case 'ended': {
        setIcon('stop_circle');
        break;
      }
      case 'paused': {
        setIcon('pause');
        break;
      }
      case 'unsuccessful': {
        setIcon('cancel');
        break;
      }
      case 'active': {
        setIcon('radio_button_checked');
        break;
      }
      case 'archived': {
        setIcon('folder');
        break;
      }
      default: {
        break;
      }
    }
  }, [status]);

  useEffect(() => {
    getIconName();
  }, [getIconName]);
  return (
    <>
      <div className={`d-flex jc-start status-text ${status}`}>
        <Icon
          className={`fs-sm pt2 status-text ${status} ${status === 'archived' ? 'material-symbols-outlined' : ''} `}
        >
          {icon}
        </Icon>
        <p className={`capitalize small pl4 pt0 status-text ${status}`}>{status}</p>
      </div>
    </>
  );
};

export default CampaignStatus;
