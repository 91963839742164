import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { getAuth } from 'firebase/auth';

import IntercomProvider from '@/contexts/IntercomContext';
import useAccountContext from '@/hooks/context/useAccountContext';

import Loading from '../Loading';
import NavBar from '../navigation/NavBar';

import { auth } from '@/firebaseSetup';

const ProtectedRoute = () => {
  const navigate = useNavigate();

  const { isAuthenticated, dispatchFirebaseAccountId, logoutUser } = useAccountContext();

  useEffect(() => {
    if (isAuthenticated) return;

    getAuth().onAuthStateChanged(async () => {
      if (!auth.currentUser) {
        logoutUser();
        return;
      }
      await dispatchFirebaseAccountId(auth.currentUser.uid);

      if (auth.currentUser && !auth.currentUser.emailVerified) {
        navigate('/email-verification');
        return;
      }
    });
  }, [dispatchFirebaseAccountId, isAuthenticated, logoutUser, navigate]);

  if (!isAuthenticated) {
    return (
      <div className="centered-loading-main h100p">
        <Loading />
      </div>
    );
  }

  return (
    <IntercomProvider>
      <NavBar />
      <Outlet />
    </IntercomProvider>
  );
};

export default ProtectedRoute;
