import React from 'react';

export const MobileScreen = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="pos-rel ml-auto mr-auto w100p max-w380 d-flex">
      <div className="pos-abs mobile-screen"> {children}</div>
      <img src="/images/images/IPhone_X.png" alt="phone" width={'50%'} className="ml-auto mr-auto z-1000" />
    </div>
  );
};
