import React from 'react';

import { Icon, SvgIcon } from '@mui/material';

import { Icons } from '@/constants/Icons';

const CustomIcons = ({ name, className }: { name: string; className?: string }) => {
  if (Icons[name]) {
    return (
      <div className={className}>
        <SvgIcon component={Icons[name]} />
      </div>
    );
  }

  return <Icon className={className}>{name}</Icon>;
};

export default CustomIcons;
